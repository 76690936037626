import { InfiniteData, useInfiniteQuery, useMutation, useQuery } from "react-query";
import { fetchBookingCreate } from "./fetchBookingCreate";
import { SearchOffersResponse, fetchOffers } from "./fetchOffers";
import { fetchSingleUnitGroupById } from "./fetchSingleUnitGroupById";
import { fetchUnitGroupListByAttributes, PerPage, UnitGroupsResponse } from "./fetchUnitGroupListByAttributes";
import { fetchProperties } from "./fetchProperties";
import { fetchAllUnitGroupAttributes } from "./fetchAllUnitGroupAttributes";
import { fetchAllPropertyAttributes } from "./fetchAllPropertyAttributes";
import { fetchAllHotelsPromotionLabels } from "./fetchAllHotelsPromotionLabels";
import { fetchFutureOffers, FutureOffersResponse } from "./fetchFutureOffers";
import { fetchUnitGroupPromotionLabels } from "./fetchUnitGroupPromotionLabels";
import { ExtendedHotelDto } from "./aggregation-client/service";

/**
 *
 */
export const useListProperties = () => {
  return useQuery<ExtendedHotelDto[]>(["properties"], () => fetchProperties());
};

/**
 *
 * @returns
 */
export const useGetAllPropertyAttributes = () => {
  return useQuery(["all-hotel-attributes"], () => fetchAllPropertyAttributes(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @returns
 */
export const useGetAllUnitAttributes = () => {
  return useQuery(["all-unit-group-attributes"], () => fetchAllUnitGroupAttributes(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @returns
 */
export const useGetAllHotelsPromotionLabels = () => {
  return useQuery(["all-hotels-promotion-labels"], () => fetchAllHotelsPromotionLabels(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @returns
 */
export const useGetUnitGroupPromotionLabels = () => {
  return useQuery(["all-unit-group-promotion-labels"], () => fetchUnitGroupPromotionLabels(), {
    cacheTime: 99999,
  });
};

/**
 *
 * @param id
 * @returns
 */
export const useGetSingleUnitGroupById = (id: string) => {
  return useQuery(["unit-group-single", id], () => fetchSingleUnitGroupById(id), {
    enabled: !!id,
  });
};

/**
 *
 * @param p
 * @returns
 */
export const useListUnitGroup = (p?: Parameters<typeof fetchUnitGroupListByAttributes>[0]) => {
  return useQuery<UnitGroupsResponse>(["unit-groups"], () => fetchUnitGroupListByAttributes(p || {}));
};

/**
 *
 * @param hotelId
 * @param attributes
 * @returns
 */
export const useInfiniteListUnitGroup = (
  cityId?: number,
  hotelIds?: string,
  attributes?: string,
  sortOrder?: string,
  perPage?: PerPage,
  locationCoordinates?: string,
  options?: {
    onSuccess?: (data: InfiniteData<UnitGroupsResponse>) => void;
  }
) => {
  return useInfiniteQuery<UnitGroupsResponse>(
    ["infinite-unit-groups", JSON.stringify({ cityId, hotelIds, attributes, sortOrder, perPage, locationCoordinates })],
    ({ pageParam: page }) => {
      return fetchUnitGroupListByAttributes({
        page,
        cityId,
        hotelIds,
        attributes,
        sortOrder,
        perPage,
        locationCoordinates,
      });
    },
    {
      enabled: Boolean(sortOrder),
      getNextPageParam: (meta) => {
        return meta.paginationResult.hasNext && meta.paginationResult.currentPage + 1;
      },
      onSuccess: options?.onSuccess,
    }
  );
};

/**
 *
 */
export const useListOffers = (p: Parameters<typeof fetchOffers>[0]) => {
  return useQuery(["offers-list", JSON.stringify(p)], () => fetchOffers(p), { enabled: Boolean(p.sortOrder) });
};

/**
 *
 * @param p
 * @returns
 */
export const useInfiniteListOffers = (
  p: Parameters<typeof fetchOffers>[0] & {
    pagePath?: string;
    perPage: number;
    bannerBlockId?: string;
  },
  options?: {
    onSuccess?: (data: InfiniteData<SearchOffersResponse>) => void;
  }
) => {
  const { perPage, bannerBlockId, ...params } = p;

  return useInfiniteQuery<SearchOffersResponse>(
    ["infinite-offers-list", perPage, JSON.stringify(params)],
    ({ pageParam: page, signal }) => {
      return fetchOffers(
        {
          ...params,
          page,
          perPage,
          // Debug purpose
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          bannerBlockId,
        },
        { signal }
      );
    },
    {
      enabled: Boolean(p.sortOrder),
      getNextPageParam: (meta) => {
        return meta.paginationResult.hasNext && meta.paginationResult?.currentPage + 1;
      },
      onSuccess: options?.onSuccess,
    }
  );
};

/**
 *
 * @param p
 * @returns
 */

export const useInfiniteListFutureOffers = (
  p: Parameters<typeof fetchOffers>[0] & {
    pagePath?: string;
    perPage: number;
    bannerBlockId?: string;
  },
  options?: {
    onSuccess?: (data: InfiniteData<FutureOffersResponse>) => void;
  }
) => {
  const { perPage, bannerBlockId, ...params } = p;

  return useInfiniteQuery<FutureOffersResponse>(
    ["infinite-future-offers-list", perPage, JSON.stringify(params)],
    ({ pageParam: page, signal }) => {
      return fetchFutureOffers(
        {
          ...params,
          perPage,
          page,
          // Debug purpose
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          bannerBlockId,
        },
        { signal }
      );
    },
    {
      enabled: Boolean(p.sortOrder),
      getNextPageParam: (meta) => {
        return meta.paginationResult.hasNext && meta.paginationResult?.currentPage + 1;
      },
      onSuccess: options?.onSuccess,
    }
  );
};

/**
 *
 * @returns
 */
export const useCreateBooking = () => {
  return useMutation({
    // TODO: ?
    mutationKey: "booking-create-uuid-goes-here",
    mutationFn: (params: Parameters<typeof fetchBookingCreate>[0]) => fetchBookingCreate(params),
  });
};
