import { useMemo } from "react";

import { FreeMode, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { SwiperProps, SwiperRef } from "swiper/react";

type Props = {
  renderType: "button" | "photo";
};

type ReturnType = React.RefAttributes<SwiperRef> & SwiperProps;

export function useSliderSettings({ renderType }: Props): ReturnType {
  return useMemo(() => {
    if (renderType === "button") {
      return {
        spaceBetween: 8,
        slidesPerView: "auto",
        freeMode: false,
        loop: false,
        navigation: { nextEl: ".arrow-right", prevEl: ".arrow-left" },
        slidesPerGroupAuto: true,
        slidesPerGroup: 1,
        slidesOffsetAfter: 16,
        modules: [FreeMode, Navigation],
      } as ReturnType;
    }

    return {
      spaceBetween: 0,
      slidesPerView: 3,
      freeMode: true,
      loop: false,

      navigation: { nextEl: ".arrow-right", prevEl: ".arrow-left" },
      breakpoints: {
        480: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 5,
        },
        780: {
          slidesPerView: 6,
        },
        1024: {
          slidesPerView: 8,
        },
      },
      modules: [FreeMode, Navigation],
    } as ReturnType;
  }, [renderType]);
}
