// @mui
import { Box, Button } from "@mui/material";

import { useTheme } from "@mui/system";
import { AttributeButtonSliderItemSkeleton } from "./AttributeButtonSliderItemSkeleton";
import { HotelAttributeDto, HotelUnitGroupAttributeDto } from "../../../../../api/aggregation-client/service";

// ----------------------------------------------------------------------

type Props = {
  attribute: HotelUnitGroupAttributeDto | HotelAttributeDto;
  isLoading?: boolean;
  hasPostDivider?: boolean;
};

export function AttributeButtonSliderItem({ attribute, isLoading, hasPostDivider }: Props) {
  const theme = useTheme();

  if (isLoading) {
    return <AttributeButtonSliderItemSkeleton />;
  }

  return (
    <>
      <Box key={attribute.id}>
        <Button
          size="small"
          color="secondary"
          sx={{ whiteSpace: "nowrap" }}
          variant="outlined"
          href={`/search?filters=${attribute.id}`}
        >
          {attribute.title}
        </Button>
      </Box>

      {/* Divider between unit type attributes and unit amenity attributes */}
      {hasPostDivider && (
        <Box>
          <Box ml={2} mr={1} sx={{ background: theme.palette.grey[500], width: 2, height: "100%" }} />
        </Box>
      )}
    </>
  );
}
