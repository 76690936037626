import dayjs, { Dayjs } from "dayjs";
import router from "next/router";
import { atom, selector } from "recoil";
import { makeUrl } from "../fe-ui/utils/makeUrl";
import { adultsCounterAtom, childrenCounterAtom } from "./booking-state";
import { MAX_PRICE, MIN_PRICE } from "../config-global";
import { SearchBounds } from "../ui/widgets/SearchMap/types";

export type IDateRangeSerialized = [string | null, string | null];

export const dateRangeAtom = atom<IDateRangeSerialized>({
  key: "search/date-range",
  default: (() => {
    return [null, null];
  })(),
  effects: [
    ({ onSet }) => {
      onSet(([arrivalDate, departureDate]) => {
        if (arrivalDate && departureDate && router.query && router.query.departureDate && router.query.arrivalDate) {
          router.replace(
            {
              query: { ...router.query, arrivalDate, departureDate },
            },
            undefined,
            {
              shallow: true,
            }
          );
        }
      });
    },
  ],
});

export const dateRangeAvailableSelector = selector({
  key: "search/date-range-is-available",
  get({ get }) {
    const [a, b] = get(dateRangeAtom);
    return Boolean(a && b);
  },
});

export const dateRangeNightsCounterSelector = selector({
  key: "search/date-range-nights-count",
  get({ get }) {
    const isAvailable = get(dateRangeAvailableSelector);
    if (!isAvailable) {
      return 0;
    }
    const [a, b] = get(dateRangeAtom);
    return dayjs(b).diff(dayjs(a), "days");
  },
});

export const singleHotelIdContextAtom = atom<string>({
  key: "search/single-hotel-id",
  default: "",
});

// TODO: Move to UI state
export const filterWidgetShownAtom = atom<boolean>({
  key: "search/widget-shown",
  default: false,
});

export const filterSelectedAttributeIdsAtom = atom<number[]>({
  key: "search/selected-attrs",
  default: [],
  effects: [],
});

export const filterSelectedAttributeIdsSelector = selector({
  key: "search/selected-attrs-ids",
  get({ get }) {
    return get(filterSelectedAttributeIdsAtom);
  },
});

export const searchFormSelector = selector({
  key: "search/search-form-selectors",
  get({ get }) {
    const dateRangeSource = get(dateRangeAtom);
    const dateRange = dateRangeSource.map((d) => (d ? dayjs(d) : null)) as [Dayjs | null, Dayjs | null];
    return {
      dateRange,
    };
  },
});

export const selectedCityIdAtom = atom<number | undefined>({
  key: "search/city-id",
  default: undefined,
  effects: [
    ({ onSet }) => {
      onSet((cityId) => {
        if (cityId !== router.query.cityId) {
          router.replace(
            {
              query: { ...router.query, cityId },
            },
            undefined,
            {
              shallow: true,
            }
          );
        }
      });
    },
  ],
});

export const selectedCityNameAtom = atom<string | undefined>({
  key: "search/city-name",
  default: undefined,
});

export const selectedHotelAtom = atom<string>({
  key: "search/hotel",
  default: "",
});

export const sortByPriceOrIsNewAtom = atom<"asc" | "desc" | "is_new" | undefined>({
  key: "search/sortByPriceOrIsNew",
  default: undefined,
});

export const minPriceAtom = atom<number>({
  key: "search/minPrice",
  default: MIN_PRICE,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        const query = { ...router.query, minPrice: newValue.toString() };
        router.replace({ query }, undefined, { shallow: true });
      });
    },
  ],
});

export const maxPriceAtom = atom<number>({
  key: "search/maxPrice",
  default: MAX_PRICE,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        const query = { ...router.query, maxPrice: newValue.toString() };
        router.replace({ query }, undefined, { shallow: true });
      });
    },
  ],
});

export const searchFullUrlSelector = selector({
  key: "search/search-full-url-string",
  get({ get }) {
    const cityId = get(selectedCityIdAtom) ?? "";
    const hotelId = get(selectedHotelAtom);
    const dateRangeSource = get(dateRangeAtom);
    const [arrivalDate, departureDate] = dateRangeSource;
    // Add hotels filter by default
    const filters = get(filterSelectedAttributeIdsSelector).toString();
    const adults = get(adultsCounterAtom);
    const children = get(childrenCounterAtom);
    const sort = get(sortByPriceOrIsNewAtom);

    return makeUrl("/search", {
      cityId,
      hotelId,
      arrivalDate,
      departureDate,
      filters,
      children,
      adults,
      sort,
    }) as "/search";
  },
});

export const boundCoordsAtom = atom<SearchBounds | undefined>({
  key: "search/bound-coords",
  default: undefined,
});

/**
 * Returns bound coordinates in `lng1,lat1,lng2,lat2` format.
 */
export const formattedBoundCoordsSelector = selector<string | undefined>({
  key: "search/formatted-bound-coords-selector",
  get: ({ get }) => {
    const bounds = get(boundCoordsAtom);

    if (!bounds) {
      return undefined;
    }

    const { lng1, lat1, lng2, lat2 } = bounds;

    return `${lng1},${lat1},${lng2},${lat2}`;
  },
});
