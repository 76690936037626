import { useMemo } from "react";
import { HotelUnitGroupAttributeDto, HotelUnitGroupAttributeGroupsDto } from "../api/aggregation-client/service";

type Props = {
  unitAttributeGroups: HotelUnitGroupAttributeGroupsDto[];
};

export const useUnitAttributesForAttributeSlider = ({
  unitAttributeGroups,
}: Props): {
  unitTypeAttributes: HotelUnitGroupAttributeDto[];
  unitAmenityAttributes: HotelUnitGroupAttributeDto[];
} => {
  return useMemo(() => {
    const unitAttributes = unitAttributeGroups
      .map((g) => g.attributes)
      .flat()
      .filter((a) => a.show_in_main_slider)
      .sort((a, b) => Number(a.photo) - Number(b.photo) || b.priority - a.priority);
    const unitTypeAttributes = unitAttributes.filter((a) => !!a.photo);
    const unitAmenityAttributes = unitAttributes.filter((a) => !a.photo);

    return {
      unitTypeAttributes,
      unitAmenityAttributes,
    };
  }, [unitAttributeGroups]);
};
