// @mui
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTheme } from "@mui/material/styles";

import { useSliderSettings } from "./useSliderSettings";
import { SamplePrevArrow } from "./compounds/SamplePrevArrow";
import { SampleNextArrow } from "./compounds/SampleNextArrow";
import type { HotelUnitGroupAttributeGroupsDto } from "../../../api/aggregation-client/service";
import { useUnitAttributesForAttributeSlider } from "../../../hooks/useUnitAttributesForAttributeSlider";
import { AttributeButtonSliderItem } from "./compounds/AttributeButtonSliderItem/AttributeButtonSliderItem";

type Props = {
  dataTestId: string;
  unitAttributes: HotelUnitGroupAttributeGroupsDto[];
  renderType: "button" | "photo";
};

export const AttributeSlider = ({ dataTestId, unitAttributes: allUnitGroupAttributes, renderType }: Props) => {
  const theme = useTheme();
  const settings = useSliderSettings({ renderType });
  const unitAttributes = useUnitAttributesForAttributeSlider({ unitAttributeGroups: allUnitGroupAttributes });

  if (!allUnitGroupAttributes.length) {
    return null;
  }

  // When renderType = "photo"
  // const attributes = allUnitGroupAttributes
  //   .map((g) => g.attributes)
  //   .flat()
  //   .filter((attr) => !!attr.icon)
  //   .filter((attr) => attr.show_in_main_slider);

  // if (!attributes.length) {
  //   return null;
  // }

  return (
    <Box mx="auto" px={4} position={"relative"} data-testid={dataTestId}>
      <Swiper {...settings}>
        {unitAttributes.unitTypeAttributes.map((attr, idx, arr) => (
          <SwiperSlide
            key={attr.code}
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <AttributeButtonSliderItem
              attribute={attr}
              hasPostDivider={idx === arr.length - 1 && unitAttributes.unitAmenityAttributes.length > 0}
            />
          </SwiperSlide>
        ))}
        {unitAttributes.unitAmenityAttributes.map((attr) => (
          <SwiperSlide
            key={attr.code}
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <AttributeButtonSliderItem attribute={attr} />
          </SwiperSlide>
        ))}
      </Swiper>
      <SamplePrevArrow className="arrow-left" bgColor={theme.palette.background.default} />
      <SampleNextArrow className="arrow-right" bgColor={theme.palette.background.default} />
    </Box>
  );
};
