import { useTheme } from "@mui/material/styles";
import { Logo, LanguagePopover, Iconify } from "../../components";
import dynamic from "next/dynamic";
import {
  Box,
  AppBar,
  Toolbar,
  Container,
  BoxProps,
  useMediaQuery,
  IconButton,
  Link as MuiLink,
  Button,
} from "@mui/material";
import useOffSetTop from "../../../fe-ui/hooks/use-offset-top";
import { bgBlur } from "../../../fe-ui/utils/cssStyles";
import { HEADER } from "../../../config-global";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { useRecoilValue, useResetRecoilState, useRecoilState } from "recoil";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { uiInitialLogoDarkForced } from "../../../state/ui-state";
import dataTestIds from "../../../utils/data-test-ids";
import NavMobile from "./nav/mobile";
import { NavItemProps } from "./nav/types";
import { bookingLastCreatedBookingState, bookingLastCreatedBookingTimeoutState } from "../../../state/booking-state";
import { EnumCreateBookingMachineStateOutputDtoState } from "../../../api/api-client/service";
import { ProfileDropdownLazy } from "../../components/ProfileDropdown/ProfileDropdownLazy";
import { hasAuthParams, useAuth } from "react-oidc-context";

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: "auto",
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}

const Countdown = dynamic(() => import("../../components/Countdown/Countdown").then((x) => x.Countdown), {
  ssr: false,
});

const countdownBanner = (timeout, lastCreatedBooking, setLastCreatedBookingTimeout, t, onApprove, onCancel) => {
  const bannerContent = t.rich("countdown_banner", {
    link: () => (
      <MuiLink
        href={lastCreatedBooking.paymentUrl}
        sx={{ textDecoration: "underline", color: "black", fontWeight: "bold" }}
      >
        {lastCreatedBooking.bookingId}
      </MuiLink>
    ),
  });

  const timeoutMinutes = Math.floor(timeout / 60);
  const timeoutSeconds = timeout % 60;

  return (
    <Countdown
      hours={0}
      minutes={timeoutMinutes}
      seconds={timeoutSeconds}
      bannerContent={bannerContent as string}
      approveText={t("Booking_pay")}
      cancelText={t("cta_cancel")}
      onApprove={onApprove}
      onCancel={onCancel}
      onTimeoutUpdate={setLastCreatedBookingTimeout}
    />
  );
};

type Props = {
  renderHeaderContent?: () => ReactNode;
  enableMobileNav?: boolean;
  mobileNavItems?: NavItemProps[];
};

export default function Header({ renderHeaderContent, enableMobileNav, mobileNavItems }: Props) {
  const theme = useTheme();
  const t = useTranslation();
  const auth = useAuth();
  const { pathname, query } = useRouter();
  const isHomePage = pathname === "/";
  const isATBBookingPage = pathname.startsWith("/booking") && query.from === "atb";
  const isSearchResultsPage = pathname === "/search";
  const isContactsPage = pathname === "/contact-us";
  const isDarkForced = useRecoilValue(uiInitialLogoDarkForced);
  const isOffset = useOffSetTop(10);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const lastCreatedBooking = useRecoilValue(bookingLastCreatedBookingState);
  const [lastCreatedBookingTimeout, setLastCreatedBookingTimeout] = useRecoilState(
    bookingLastCreatedBookingTimeoutState
  );
  const resetLastCreatedBooking = useResetRecoilState(bookingLastCreatedBookingState);
  const resetLastCreatedBookingTimeout = useResetRecoilState(bookingLastCreatedBookingTimeoutState);
  const redirectToPayment = () => {
    if (lastCreatedBooking?.paymentUrl) window.location.href = lastCreatedBooking?.paymentUrl;
  };
  const onBookingPaymentCancel = () => {
    resetLastCreatedBooking();
    resetLastCreatedBookingTimeout();
  };

  useEffect(() => {
    if (!auth.isLoading && auth.error && hasAuthParams()) {
      // If query contains invalid "code" or "state" param,
      // redirect user to same pathname, but without query params.
      window.location.replace(window.location.origin + window.location.pathname);
    }
  }, [auth.error, auth.isLoading]);

  return (
    <AppBar id="application-header" color="transparent" sx={{ boxShadow: 0 }}>
      {lastCreatedBooking &&
        lastCreatedBooking.paymentUrl &&
        lastCreatedBooking.failedPaymentAttempts < 5 &&
        lastCreatedBooking._state === EnumCreateBookingMachineStateOutputDtoState.waitForPayment &&
        countdownBanner(
          lastCreatedBookingTimeout,
          lastCreatedBooking,
          setLastCreatedBookingTimeout,
          t,
          redirectToPayment,
          onBookingPaymentCancel
        )}

      {!isATBBookingPage && (
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_MAIN_DESKTOP,
            },
            transition: theme.transitions.create(["height", "background-color"], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(isOffset && {
              ...bgBlur({ color: theme.palette.background.default }),
            }),
          }}
        >
          <Container sx={{ height: 1, display: "flex", alignItems: "center" }}>
            <Logo isDark={isDarkForced || !!isOffset || !isHomePage} />

            {(isHomePage || isContactsPage) && !isMobile && (
              <Box
                ml={2}
                sx={{
                  color: isContactsPage || isDarkForced || isOffset ? "#223A6D" : theme.palette.background.default,
                  fontWeight: 600,
                  fontSize: [12, 14, 16],
                }}
              >
                {t("index-main-title")}
              </Box>
            )}

            <Box sx={{ flexGrow: 1 }}>{renderHeaderContent?.()}</Box>

            {(isHomePage || isSearchResultsPage || isContactsPage) && !isMobile && (
              <IconButton
                sx={{
                  height: 32,
                  boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
                  borderRadius: "35px",
                  backgroundColor: theme.palette.background.default,
                  "&:hover": {
                    backgroundColor: theme.palette.background.default,
                  },
                  zIndex: 1,
                  color: "text.primary",
                  paddingX: "12px",
                  paddingY: "6px",
                  paddingLeft: "8px",
                }}
              >
                <MuiLink
                  target="_blank"
                  href={`https://wa.me/${t("contacts_phone")}`}
                  color={theme.palette.text.primary}
                  data-testid={dataTestIds?.HEADER.ASK_QUESTION}
                  data-ym={"HEADER_ASK_QUESTION"}
                  sx={{
                    display: "inline-flex",
                    gap: 0.5,
                    alignItems: "center",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  <Iconify icon="eva:question-mark-circle-outline" />
                  {t("ask-question")}
                </MuiLink>
              </IconButton>
            )}

            <LanguagePopover
              isOffset={isOffset}
              dataTestIds={{
                button: dataTestIds.LANGUAGE_POPOVER.BUTTON,
                menuItem: {
                  az: dataTestIds.LANGUAGE_POPOVER.MENU_ITEM.AZ,
                  ru: dataTestIds.LANGUAGE_POPOVER.MENU_ITEM.RU,
                  en: dataTestIds.LANGUAGE_POPOVER.MENU_ITEM.EN,
                },
              }}
              hideOnMobile={isHomePage}
            />

            <ProfileDropdownLazy
              dataTestIds={{
                button: dataTestIds.PROFILE_DROPDOWN.BUTTON,
                menuItem: {
                  profile: dataTestIds.PROFILE_DROPDOWN.MENU_ITEM.PROFILE,
                  properties: dataTestIds.PROFILE_DROPDOWN.MENU_ITEM.PROPERTIES,
                  bookings: dataTestIds.PROFILE_DROPDOWN.MENU_ITEM.BOOKINGS,
                  logout: dataTestIds.PROFILE_DROPDOWN.MENU_ITEM.LOGOUT,
                },
              }}
            />

            {!auth.isAuthenticated && isHomePage && (
              <Button
                sx={{
                  borderRadius: 35,
                  height: 32,
                  ml: 1,
                }}
                variant="contained"
                onClick={() => auth.signinRedirect()}
              >
                {t("nav-cta-upload-unit")}
              </Button>
            )}

            {enableMobileNav && isMobile && (
              <NavMobile
                isOffset={isOffset}
                data={mobileNavItems ?? []}
                isMainLayout={isHomePage || isSearchResultsPage}
              />
            )}
          </Container>
        </Toolbar>
      )}

      {isOffset && <Shadow />}
    </AppBar>
  );
}
