import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";
import {
  selectedCityIdAtom,
  dateRangeAtom,
  filterSelectedAttributeIdsAtom,
  selectedHotelAtom,
  sortByPriceOrIsNewAtom,
  maxPriceAtom,
  minPriceAtom,
} from "../state/search-state";
import { adultsCounterAtom, bookingReferralState, childrenCounterAtom } from "../state/booking-state";
import { parseFiltersParamToAttrIds } from "../utils/query-param-parsers";
import {
  MAX_ADULTS_COUNT,
  MAX_CHILDREN_COUNT,
  MAX_PRICE,
  MIN_ADULTS_COUNT,
  MIN_CHILDREN_COUNT,
  MIN_PRICE,
} from "../config-global";

export const useInitialStateFromQuery = () => {
  const router = useRouter();
  const setCityId = useSetRecoilState(selectedCityIdAtom);
  const setHotelId = useSetRecoilState(selectedHotelAtom);
  const setDateRange = useSetRecoilState(dateRangeAtom);
  const setSelectedAttrs = useSetRecoilState(filterSelectedAttributeIdsAtom);
  const setAdults = useSetRecoilState(adultsCounterAtom);
  const setChildren = useSetRecoilState(childrenCounterAtom);
  const setReferral = useSetRecoilState(bookingReferralState);
  const setSort = useSetRecoilState(sortByPriceOrIsNewAtom);
  const setMaxPrice = useSetRecoilState(maxPriceAtom);
  const setMinPrice = useSetRecoilState(minPriceAtom);

  const lastQueryRef = useRef(router.query); // Store the last query

  const updateStateFromQuery = (query) => {
    const {
      cityId,
      hotelId,
      arrivalDate,
      departureDate,
      filters,
      adults,
      children,
      referral,
      sort,
      maxPrice,
      minPrice,
    } = query;

    if (sort === "asc" || sort === "desc" || sort === "is_new") {
      setSort(sort);
    } else {
      setSort("asc");
    }

    let maxPriceNum: number | null = null;
    if (maxPrice && !Number.isNaN(Number(maxPrice)) && Number(maxPrice) >= MIN_PRICE && Number(maxPrice) <= MAX_PRICE) {
      maxPriceNum = Number(maxPrice);
      setMaxPrice(Number(maxPrice));
    }

    if (
      minPrice &&
      !Number.isNaN(Number(minPrice)) &&
      Number(minPrice) >= MIN_PRICE &&
      Number(minPrice) <= Math.min(maxPriceNum ?? MAX_PRICE, MAX_PRICE)
    ) {
      setMinPrice(Number(minPrice));
    }

    if (cityId && !Number.isNaN(Number(cityId))) {
      setCityId(Number(cityId));
    }

    if (hotelId && hotelId !== "null") {
      setHotelId(hotelId);
    }

    if (arrivalDate && departureDate && arrivalDate !== "null" && departureDate !== "null") {
      setDateRange([arrivalDate, departureDate]);
    }

    setSelectedAttrs(parseFiltersParamToAttrIds(filters));

    if (
      adults &&
      !Number.isNaN(Number(adults)) &&
      Number(adults) >= MIN_ADULTS_COUNT &&
      Number(adults) <= MAX_ADULTS_COUNT
    ) {
      setAdults(Number(adults));
    }

    if (
      children &&
      !Number.isNaN(Number(children)) &&
      Number(children) >= MIN_CHILDREN_COUNT &&
      Number(children) <= MAX_CHILDREN_COUNT
    ) {
      setChildren(Number(children));
    }

    if (referral) {
      setReferral(referral);
    }
  };

  useEffect(() => {
    // Initial state update
    updateStateFromQuery(router.query);
    lastQueryRef.current = router.query;

    const handleRouteChange = (url) => {
      const newQuery = new URLSearchParams(url.split("?")[1]);
      const query = Object.fromEntries(newQuery.entries());

      // Only update state if the query has changed
      if (JSON.stringify(query) !== JSON.stringify(lastQueryRef.current)) {
        updateStateFromQuery(query);
        lastQueryRef.current = query; // Update the ref to the new query
      }
    };

    // Subscribe to route changes
    router.events.on("routeChangeComplete", handleRouteChange);

    // Cleanup on unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};
