import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { Box, FormControl, MenuItem, Select, SxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState, useSetRecoilState } from "recoil";
import { selectedCityIdAtom, selectedCityNameAtom } from "../../../state/search-state";
import dataTestIds from "../../../utils/data-test-ids";
import { useLocale } from "next-intl";
import DropdownIcon from "./icons/DropdownIcon";
import { useEffect } from "react";
import { useGetCities } from "../../../api/useGetCities";
import { ValidCity } from "../../../api/fetchCities";

// ----------------------------------------------------------------------

type Props = {
  cities?: ValidCity[];
  isCompact?: boolean;
  isSheet?: boolean;
};

const ALL_CITIES_MENU_ITEM_VALUE = "all_cities";

function FormSearchCityPickerBody({
  cities,
  isCompact = false,
  isSheet = false,
}: Props & {
  cities: ValidCity[];
}) {
  const [selectedCityId, selectCityId] = useRecoilState(selectedCityIdAtom);
  const selectCityName = useSetRecoilState(selectedCityNameAtom);

  const t = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const menuItemSxProps: SxProps<Theme> = {
    borderRadius: "6px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    padding: "6px 16px",
    "&:hover": {
      backgroundColor: `${theme.palette.action.hover} !important`,
    },
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.action.selected} !important`,
      "&:hover": {
        backgroundColor: `${theme.palette.action.hover} !important`,
      },
    },
  };

  useEffect(() => {
    const cityName = selectedCityId != null ? cities.find((city) => city.id === selectedCityId)?.title : undefined;
    selectCityName(cityName);
  }, [selectedCityId, cities]);

  useEffect(() => {
    if (selectedCityId != null && cities && cities.length > 0) {
      const relatedCity = cities.find((city) => city.id === selectedCityId);
      if (!relatedCity) {
        selectCityId(undefined);
      }
    }
  }, [selectedCityId, cities]);

  return (
    <FormControl
      sx={{
        backgroundColor: isCompact ? "transparent" : isSheet ? theme.palette.background.neutral : "white",
        ...((isCompact || (!isSheet && !isMobile)) && {
          border: `1px solid ${theme.palette.divider}`,
        }),
        ...(isSheet && { borderRadius: "8px" }),
        ...(!isSheet && {
          borderTopLeftRadius: isCompact ? (isMobile ? undefined : "16px") : "16px",
          borderTopRightRadius: isCompact ? (isMobile ? undefined : undefined) : isMobile ? "16px" : undefined,
          borderBottomLeftRadius: isCompact ? (isMobile ? undefined : "16px") : isMobile ? undefined : "16px",
        }),
        height: isCompact ? "62px" : isMobile || isSheet ? "56px" : "68px",
        whiteSpace: "nowrap",
      }}
    >
      {!isCompact && (
        <Typography
          sx={{
            color: "text.secondary",
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "20px",
            marginTop: "-20px",
            position: "relative",
            top: isMobile || isSheet ? "28px" : "32px",
            left: "16px",
            userSelect: "none",
          }}
        >
          {t("search_form_city_label")}
        </Typography>
      )}
      <Select
        displayEmpty
        placeholder={t("Common_allCities")}
        variant="outlined"
        data-ym={"CITY_PICKER"}
        sx={{
          borderRadius: 0,
          ...(isSheet && { borderRadius: "8px" }),
          ...(!isSheet && {
            borderTopLeftRadius: isCompact ? (isMobile ? undefined : "16px") : "16px",
            borderTopRightRadius: isCompact ? (isMobile ? undefined : undefined) : isMobile ? "16px" : undefined,
            borderBottomLeftRadius: isCompact ? (isMobile ? undefined : "16px") : isMobile ? undefined : "16px",
          }),
          fontWeight: 600,
          fontSize: isMobile || isSheet ? "14px" : "16px",
          lineHeight: isMobile || isSheet ? "20px" : "24px",
          transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "& fieldset": {
            border: "none",
          },
          "& .MuiSelect-select": {
            width: "100%",
            padding: isCompact
              ? "19px 12px 19px 16px !important"
              : isMobile || isSheet
              ? "28px 12px 8px 16px !important"
              : "32px 12px 12px 16px !important",
          },
          "& .MuiInputBase-inputSizeSmall": {
            py: 1,
          },
          "&:hover": {
            backgroundColor: "rgba(145, 158, 171, 0.08)", // = #F4F6F7 when over white
          },
        }}
        IconComponent={() => null}
        MenuProps={{
          PaperProps: {
            sx: {
              padding: "8px",
            },
          },
          MenuListProps: {
            sx: {
              padding: 0,
            },
          },
        }}
        value={cities.find((city) => city.id === selectedCityId)?.id ?? ALL_CITIES_MENU_ITEM_VALUE}
        renderValue={(value) => {
          const cityTitle =
            value === ALL_CITIES_MENU_ITEM_VALUE
              ? t("Common_allCities")
              : cities.find((city) => city.id === selectedCityId)?.title;
          return (
            <Box sx={{ display: "flex", justifyContent: cityTitle ? "space-between" : "end", gap: "8px" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile || isSheet ? "14px" : "16px",
                  lineHeight: isMobile || isSheet ? "20px" : "24px",
                }}
              >
                {value === ALL_CITIES_MENU_ITEM_VALUE
                  ? t("Common_allCities")
                  : cities.find((city) => city.id === selectedCityId)?.title}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DropdownIcon />
              </Box>
            </Box>
          );
        }}
        onChange={(e) => {
          selectCityId(e.target.value !== ALL_CITIES_MENU_ITEM_VALUE ? Number(e.target.value) : undefined);
        }}
        data-testid={dataTestIds.SEARCH.CITY_PICKER.BUTTON}
      >
        <MenuItem
          sx={menuItemSxProps}
          key={ALL_CITIES_MENU_ITEM_VALUE}
          value={ALL_CITIES_MENU_ITEM_VALUE}
          data-testid={dataTestIds.SEARCH.CITY_PICKER.MENU_ITEM.ALL_CITIES}
        >
          {t("Common_allCities")}
        </MenuItem>
        {cities?.map((city) => (
          <MenuItem sx={menuItemSxProps} key={city.id} value={city.id}>
            {city.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function FormSearchCityPickerDynamic(p: Props) {
  const language = useLocale();
  const { data: cities } = useGetCities({ language, useShowOnSearchFilter: true });

  return <FormSearchCityPickerBody {...p} cities={cities ?? []} />;
}

export default function FormSearchCityPicker(p: Props) {
  if (p.cities && p.cities.length) {
    return <FormSearchCityPickerBody {...p} cities={p.cities} />;
  }
  return <FormSearchCityPickerDynamic {...p} />;
}
